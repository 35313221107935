/* ===== VARIABLES ===== */
$open-sans: 'Open Sans', sans-serif;
$white: #ffffff;
$black: #000000;
$blue: #257afb;
$lightBlue: #a2c7ff;
$orange: #ffc100;
$pageWidth: 1200px;

/* ===== MIXINS ===== */
@mixin size($sizeValue: 1) {
    size: $sizeValue + rem;
}

@mixin placeholder {
    ::-webkit-input-placeholder {
        @content;
    }
    :-moz-placeholder {
        @content;
    }
    ::-moz-placeholder {
        @content;
    }
    :-ms-input-placeholder {
        @content;
    }
}

/* ===== DEFAULT STYLES ===== */
html,
body,
div,
span,
p,
img,
footer {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

body {
    width: 100%;
    margin: 0 auto;
    line-height: 1;
    font-family: $open-sans;
    overflow-x: hidden;
}

a {
    transition: color 0.2s;
    text-decoration: none;
    color: inherit;
}

h1 {
    font-size: 75px;
    font-weight: 800;
    @media (max-width: 600px) {
        font-size: 50px;
    }
}

h2 {
    font-size: 75px;
    font-weight: 800;
    text-transform: uppercase;
    @media (max-width: 600px) {
        font-size: 35px;
    }
}

h3 {
    font-size: 30px;
    font-weight: 800;
}

p {
    color: #666;
    font-size: 20px;
    line-height: 40px;
}

.blue-text {
    color: #febe10;
}

.bold-text {
    font-weight: 700;
}

.outofsight {
    display: none;
}

@include placeholder {
    color: #ccc;
    font: {
        @include size(0.9);
    }
}

/* ===== END DEFAULT STYLES ===== */

.core-wrapper {
    max-width: 1600px;
    margin: 0 auto;
    box-shadow:
        12px 0 15px -4px rgba(0, 0, 0, 0.1),
        -12px 0 8px -4px rgba(0, 0, 0, 0.1);
}

/* sticky nav fix for cms toolbar */
.cms-toolbar-expanded .page header {
    top: 46px !important;
}

.page {
    position: relative;
    z-index: 9;
    @media (max-width: 800px) {
        text-align: center;
    }

    .studio-placement-wrapper {
        position: absolute;
        z-index: 9;
        left: 0;
        right: 0;

        .studio-placement {
            max-width: $pageWidth;
            text-align: right;
            text-transform: uppercase;
            margin: 0 auto;
            padding-top: 3px;
            @media (max-width: 400px) {
                padding-top: 0;
            }

            a {
                color: $black;
                padding: 4px 10px 5px 10px;
                background: #ccc;
                border-radius: 0 0 5px 5px;
                font-size: 13px;
                font-weight: bold;
                @media (max-width: 400px) {
                    padding: 2px 10px 3px;
                    font-size: 11px;
                }
            }
        }
    }

    header {
        padding: 10px 50px;
        background: #000;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 8;

        .header-container {
            max-width: $pageWidth;
            margin: 0 auto;
        }

        @media (max-width: 1000px) {
            padding: 10px 20px;
        }

        .SE-logo {
            width: 300px;
            padding: 6px 0 12px;
            float: left;
            position: relative;
            z-index: 3;
            @media (max-width: 1200px) {
                width: 260px;
                padding: 14px 0 3px;
            }
            @media (max-width: 1000px) {
                padding: 3px 0;
            }
            @media (max-width: 800px) {
                padding: 2px 0 5px;
                float: none;
            }
            @media (max-width: 400px) {
                width: 200px;
            }
        }

        #navigation {
            margin: 19px 0 25px 0;
            float: right;
            @media (max-width: 1200px) {
                margin: 20px 20px 20px;
            }
            @media (max-width: 1000px) {
                margin: 11px 20px 20px 0;
            }
            @media (max-width: 800px) {
                height: 100vh;
                margin: -200vh 0 0;
                padding: 0 40px 20px;
                background: rgba(0, 0, 0, 0.9);
                position: absolute;
                left: 0;
                right: 0;
                z-index: -1;
            }

            .nav-option {
                margin: 16px 0 0 58px;
                color: $white;
                font: {
                    @include size(1.5);
                    weight: 700;
                }
                display: inline-block;
                z-index: 2;
                @media (max-width: 1200px) {
                    margin-left: 60px;
                    font: {
                        @include size(1.4);
                    }
                }
                @media (max-width: 1000px) {
                    margin-left: 40px;
                    font: {
                        @include size(1.2);
                    }
                }
                @media (max-width: 800px) {
                    margin: 30px 0;
                    padding: 10px 0;
                    border-bottom: solid 1px #ccc;
                    text-align: left;
                    display: block;
                }

                &:hover {
                    color: $orange;
                }

                &:first-of-type {
                    margin-left: 0;
                }
            }

            .nav-option.active {
                color: $orange;
            }
        }

        .fa-mobile {
            color: #ececec;
            font-size: 45px;
            position: absolute;
            top: 24px;
            right: 25px;
            display: none;
            @media (max-width: 800px) {
                display: block;
            }
            @media (max-width: 400px) {
                top: 18px;
                right: 18px;
            }
        }
    }

    .hero-wrapper {
        position: relative;

        video#hero-video {
            width: 100%;
        }

        .tagline {
            clear: both;
            margin: 0 20px;
            color: $white;
            font: {
                @include size(4.3);
                weight: 800;
            }
            text-align: center;
            text-shadow: 0 0 10px #000;
            line-height: 60px;
            letter-spacing: 2px;
            position: absolute;
            top: 60%;
            left: 0;
            right: 0;
            @media (max-width: 1000px) {
                font: {
                    @include size(3.3);
                }
                line-height: 46px;
            }
            @media (max-width: 800px) {
                font-size: 40px;
            }
            @media (max-width: 600px) {
                font-size: 28px;
                line-height: 30px;
            }
            @media (max-width: 400px) {
                font-size: 20px;
                line-height: 22px;
            }
        }

        .subtag {
            margin-top: 30px;
            padding: 0 50px;
            color: $white;
            font-size: 22px;
            font-weight: lighter;
            text-align: center;
            line-height: 25px;
            @media (max-width: 800px) {
                margin-top: 15px;
                font-size: 20px;
            }
            @media (max-width: 600px) {
                font-size: 17px;
            }
            @media (max-width: 500px) {
                margin-top: 10px;
                font-size: 15px;
                line-height: 20px;
            }

            span {
                color: $lightBlue;
                font-weight: bold;
            }
        }
    }
}

.intro-text {
    max-width: $pageWidth;
    margin: 0 auto;
    padding: 60px 100px;
    background: $white;
    @media (max-width: 800px) {
        padding: 60px;
    }
    @media (max-width: 600px) {
        padding: 30px;
    }

    a {
        color: $blue;
        font-weight: bold;
    }

    p {
        @media (max-width: 600px) {
            font-size: 20px;
            line-height: 35px;
        }
    }

    h2 strong {
        @media (max-width: 600px) {
            font-size: 25px;
        }
    }
}

#main-page.page {
    .tagline {
        @media (max-width: 800px) {
            padding-top: 35%;
            font: {
                @include size(2.5);
            }
        }
        @media (max-width: 600px) {
            padding-top: 30%;
            font: {
                @include size(2);
            }
            line-height: 38px;
        }
        @media (max-width: 500px) {
            padding-top: 20%;
            font: {
                @include size(1.5);
            }
            line-height: 30px;
        }
        @media (max-width: 400px) {
            font: {
                @include size(1.1);
            }
            line-height: 20px;
        }
    }
}

#our-services-wrapper {
    background: #333a42;
    padding: 20px 30px;
    @media (max-width: 400px) {
        padding: 10px;
    }

    #our-services {
        max-width: 1000px;
        margin: 0 auto;
        text-align: center;
        color: $white;

        .service {
            width: 150px;
            margin: 10px 20px 50px 20px;
            display: inline-block;
            vertical-align: top;
            @media (max-width: 600px) {
                width: 120px;
                margin: 0 10px 25px 10px;
            }

            i {
                margin-bottom: 20px;
                color: $white;
                font-size: 65px;
                @media (max-width: 600px) {
                    margin-bottom: 5px;
                    font-size: 40px;
                }
            }

            p {
                color: $white;
                font-size: 20px;
                line-height: 25px;
                @media (max-width: 600px) {
                    font-size: 18px;
                    line-height: 22px;
                }
            }
        }
    }
}

.recent-work-wrapper {
    background: #f6f6f6;

    .recent-work {
        max-width: $pageWidth;
        margin: 0 auto;
        padding: 20px 10px;
        text-align: center;
        clear: both;

        h2 {
            color: #7b7b7b;
        }

        h3 {
            color: $blue;
        }

        img.thumbnail {
            margin: 6px;
            @media (max-width: 600px) {
                width: 47%;
                margin: 1%;
            }
        }
    }
}

.clients-wrapper {
    padding: 20px 20px 60px 20px;
    background: #333;

    .clients {
        max-width: $pageWidth;
        margin: 0 auto;
        text-align: center;

        h1 {
            margin: 40px 0 25px 0;
            color: #a3a3a3;
        }

        img.client {
            height: 80px;
            width: auto;
            vertical-align: middle;
            margin: 20px 11px;
            @media (max-width: 600px) {
                margin: 20px auto;
                display: block;
                width: 80%;
                height: auto;
            }
        }

        img#state-dept-logo {
            margin: 0 auto;
            margin-left: 25px;
            margin-right: 25px;
            @media (max-width: 600px) {
                margin: 20px auto;
                display: block;
                width: 50%;
                height: auto;
            }
        }
    }
}

.awards-wrapper {
    padding: 20px;
    background: #f6f6f6;

    .awards {
        max-width: $pageWidth;
        margin: 0 auto;
        padding: 70px 10px;
        text-align: center;

        h1 {
            margin: 0 0 20px 0;
            color: #7b7b7b;
        }

        .award-wrap {
            .award {
                width: 148px;
                height: 270px;
                margin: 8px;
                padding: 0 16px;
                background: $white;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                position: relative;
                display: inline-block;
                vertical-align: top;

                img {
                    max-width: 148px;
                    height: 210px;
                    padding: 0 16px;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                }

                p {
                    padding: 0 16px;
                    font-size: 19px;
                    line-height: 22px;
                    position: absolute;
                    text-transform: uppercase;
                    color: #333a42;
                    bottom: 20px;
                    left: 0;
                    right: 0;
                }
            }
        }
    }
}

.contact-wrapper {
    padding: 80px 30px;
    //noinspection CssUnknownTarget
    background-image: url('img/googlemap.jpg');
    background-repeat: no-repeat;
    background-size: cover;

    .contact-form {
        max-width: $pageWidth;
        background: rgba(0, 0, 0, 0.7);
        margin: 0 auto;
        color: $white;
        text-align: center;

        h1 {
            margin-bottom: 0;
            display: inline-block;
        }

        h2 {
            margin: 0 0 50px 20px;
            font-size: 75px;
            font-weight: lighter;
            letter-spacing: 2px;
            display: inline-block;
            @media (max-width: 900px) {
                margin-left: 0;
            }
            @media (max-width: 600px) {
                font-size: 50px;
            }
        }

        form#contact {
            margin-top: 10px;
            padding: 20px 40px;
            color: $black;
            @media (max-width: 400px) {
                padding: 20px;
            }

            .left-column,
            .right-column {
                width: 46%;
                overflow: hidden;
                position: relative;
                display: inline-block;
                vertical-align: top;
                @media (max-width: 1000px) {
                    width: 100%;
                }

                a {
                    cursor: pointer;
                }

                a:hover {
                    color: $orange !important;
                }

                strong.yellow {
                    color: $orange !important;
                }

                label {
                    margin-bottom: 8px;
                    color: $white;
                    font-size: 20px;
                    font-weight: bold;
                    text-align: left;
                    display: block;
                }

                input,
                textarea,
                select {
                    width: 100%;
                    margin-bottom: 20px;
                    padding: 10px 15px;
                    background: $white;
                    border: none;
                    font-size: 20px;
                    color: $black;
                }

                select {
                    color: $black;
                    border-radius: 0;
                    appearance: none;

                    &::-ms-expand {
                        display: none;
                    }

                    -moz-appearance: none;
                    -webkit-appearance: none;

                    position: relative;
                }

                .fa-sort-desc {
                    color: $white;
                    font-size: 35px;
                    position: absolute;
                    bottom: 33px;
                    right: 10px;
                    display: none;
                }

                textarea {
                    height: 128px;
                    margin-bottom: 25px;
                }

                button {
                    width: 100%;
                    padding: 11px 30px;
                    background: none;
                    border: solid 2px $white;
                    color: $white;
                    font-size: 20px;
                    display: block;
                    cursor: pointer;
                    @media (max-width: 1000px) {
                        margin-bottom: 50px;
                    }
                }
            }

            .left-column {
                margin-right: 50px;
            }

            .right-column {
                color: #fff;
                text-align: left;

                p {
                    margin-left: 100px;
                    color: #fff;
                    text-align: left;
                    margin-bottom: 45px;
                    @media (max-width: 1200px) {
                        margin-left: 30px;
                    }
                    @media (max-width: 600px) {
                        margin-left: 0;
                    }
                }
            }
        }
    }
}

#work-page {
    .page {
        height: 620px;
        //noinspection CssUnknownTarget
        background-image: url('img/art-gallery.jpg');
        background-position: center bottom;
        background-size: cover;

        .tagline {
            color: $blue;
            text-align: center;
            @media (max-width: 600px) {
                padding-top: 7% !important;
            }
            @media (max-width: 500px) {
                font-size: 35px;
            }
            @media (max-width: 400px) {
                padding-top: 5% !important;
            }
        }
    }

    .recent-work {
        padding: 50px 10px 0;
        @media (max-width: 800px) {
            padding-top: 20px;
        }

        &::after {
            content: '';
            display: block;
            clear: both;
        }
    }

    .latest-projects-wrapper {
        .latest-projects {
            max-width: $pageWidth;
            margin: 0 auto;
            padding: 20px;

            .latest-project-large,
            .latest-project-small {
                .latest-item {
                    position: relative;
                    display: block;
                    overflow: hidden;

                    img {
                        position: relative;
                        top: 0;
                        transform: scale(1);
                        display: block;

                        &::after {
                            content: '';
                            position: absolute;
                            left: 0;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            background: linear-gradient(
                                135deg,
                                rgba(236, 45, 144, 0) 0%,
                                rgba(242, 37, 106, 0.65) 100%
                            );
                            opacity: 0;
                            transition: all 1s ease;
                            will-change: transform;
                            mix-blend-mode: lighten;
                        }
                    }
                }

                .latest-item-item-wrapper {
                    position: relative;
                    top: 0;
                    display: block;
                    background: rgba(255, 255, 255, 1);
                    box-sizing: border-box;
                    padding: 1.5em 0;
                    max-width: 100%;
                    min-height: 82px;

                    .latest-item-title {
                        font-size: 1.5em;
                        font-weight: 300;
                        margin: 0;
                        position: relative;
                        color: $black;
                        line-height: 33px;

                        span {
                            font-size: 12px;
                            font-weight: 900;
                            letter-spacing: 0.88px;
                            line-height: 16px;
                            color: #333a42;
                            padding: 10px;
                            float: right;
                            background: rgba(255, 193, 9, 0.4);

                            &:hover {
                                background-color: #333a42;
                                color: $white;
                            }
                        }
                    }

                    .latest-item-item-text {
                        p {
                            padding: 15px 0 0 0;
                            color: $black;
                            font-size: 14px;
                            font-weight: 300;
                            letter-spacing: 1px;
                            line-height: 22px;
                            text-align: left;
                        }
                    }
                }
            }

            .latest-project-small {
                width: calc(50% - 0.5em);
                margin-top: 2.5em;
                margin-bottom: 2.5em;
                margin-right: 1em;
            }
        }
    }

    .latest-projects-cols {
        display: flex;
    }

    .awards-wrapper {
        padding-top: 0;

        .awards {
            padding-top: 0;
        }
    }
}

#services-page {
    .page {
        height: 430px;
        //noinspection CssUnknownTarget
        background: url('img/airplane-hangar.jpg') center bottom;
        background-size: cover;

        .tagline {
            padding-top: 90px;
            color: $orange;
            text-shadow: 0 0 5px #000;
            text-align: center;
            @media (max-width: 600px) {
                font-size: 40px;
            }
            @media (max-width: 500px) {
                font-size: 35px;
            }
        }

        .subtag {
            @media (max-width: 1000px) {
                margin-top: 5px;
            }
        }
    }

    .services-nav {
        border-bottom: 1px solid #ccc;

        nav {
            max-width: $pageWidth;
            margin: 0 auto;
            padding: 24px;
            text-align: center;

            a {
                padding: 22px 50px;
                text-transform: uppercase;
                font-size: 14px;
                font-weight: 500;
                letter-spacing: 1.2px;
                color: #a2a2a2;

                &:hover {
                    color: $black;
                    background: #f5f5f5;
                }
            }

            .active {
                border-bottom: 4px solid #ccc;
                color: $black;
                font-weight: 600;
            }
        }
    }

    .services {
        max-width: $pageWidth;
        margin: 0 auto;
        padding-bottom: 60px;
        text-align: center;
        @media (max-width: 400px) {
            margin-top: 60px;
        }

        .service {
            width: 28%;
            margin: 35px 25px;
            display: inline-block;
            vertical-align: top;
            @media (max-width: 800px) {
                width: 200px;
                margin: 35px 15px 10px 15px;
            }
            @media (max-width: 600px) {
                width: 150px;
            }
            @media (max-width: 400px) {
                width: auto;
                margin-top: 20px;
                padding: 0 20px;
            }

            i.fa {
                color: $blue;
                font-size: 70px;
                @media (max-width: 800px) {
                    font-size: 50px;
                }
                @media (max-width: 400px) {
                    font-size: 70px;
                }
            }

            h4 {
                color: $blue;
                font-size: 19px;
                @media (max-width: 800px) {
                    margin-bottom: 10px;
                    font-size: 17px;
                }
                @media (max-width: 400px) {
                    margin-top: 15px;
                }
            }

            p {
                font-size: 18px;
                font-weight: lighter;
                line-height: 30px;
                @media (max-width: 800px) {
                    font-size: 15px;
                    line-height: 20px;
                }
            }
        }
    }

    section {
        max-width: $pageWidth;
        margin: 0 auto;
        text-align: left;
        clear: both;
        padding-top: 40px;
        @media (max-width: 1300px) {
            padding: 40px 20px 0;
            text-align: center;
        }

        p {
            text-align: left;
            font-size: 16px;
            line-height: 25px;
            margin-bottom: 30px;
            color: $black;
            @media (max-width: 1200px) {
                text-align: center;
            }

            span {
                font-size: 24px;
            }
        }

        h3 {
            text-align: left;
            font-weight: 500;
            font-size: 40px;
            margin: 0 0 10px 0;
            text-transform: capitalize;
            @media (max-width: 1200px) {
                text-align: center;
            }
            @media (max-width: 600px) {
                font-size: 35px;
            }
        }

        .heading {
            h3 {
                text-align: center;
                font-weight: 500;
                font-size: 40px;
                margin: 0;
                text-transform: capitalize;
            }

            p {
                text-align: center;
                margin-bottom: 30px;
            }
        }
    }

    .icon-section {
        &:first-of-type {
            margin-bottom: 30px;
        }

        margin-bottom: 20px;
        border-bottom: 1px solid #e7e7e7;
        width: 475px;

        .icon-wrap {
            width: 50px;
            text-align: center;
            display: inline-block;
            vertical-align: top;
        }

        .icon-body {
            width: 400px;
            display: inline-block;
            margin-left: 17px;

            p {
                text-align: left;
                line-height: 25px;
            }

            h3 {
                text-align: left;
                font-size: 19px;
                font-weight: 600;
                letter-spacing: -0.18px;
                margin: 0 0 3px 0;
            }
        }
    }

    .noborder {
        border-bottom: 0 !important;
    }

    .threeCol {
        width: 367px;
        margin: 0 15px 0;
        display: inline-block;
        vertical-align: top;
        text-align: center;
        @media (max-width: 500px) {
            width: 100%;
            margin: 0 0 30px;
        }

        p {
            font-size: 16px;
            line-height: 32px;
            text-align: center;
        }
    }

    .two {
        background: $orange;
        display: block;
        margin: 40px 0 30px;

        .icon-section {
            border-bottom: 1px solid #e0aa03;
        }

        .left {
            display: inline-block;
            width: 650px;
            margin-right: 60px;
            position: relative;
            margin-bottom: -2px;
            vertical-align: bottom;
        }

        .right {
            display: inline-block;
            width: 485px;
            vertical-align: top;
        }

        img {
            width: 650px;
            height: auto;
        }
    }

    .three {
        .left {
            display: inline-block;
            width: 50%;
            margin-right: 60px;
            position: relative;
            vertical-align: top;
        }

        .right {
            width: 43%;
            display: inline-block;
            vertical-align: top;
        }

        .left,
        .right {
            @media (max-width: 1000px) {
                width: auto;
                margin: 0;
            }

            .icon-wrap {
                @media (max-width: 1000px) {
                    width: auto;
                    margin-right: 15px;
                    float: left;
                }
            }

            .icon-section,
            .icon-body {
                @media (max-width: 1000px) {
                    width: auto;
                    margin: auto;
                    //padding: 0 20px;
                    display: block;
                }
            }

            .icon-section,
            .icon-section.noborder {
                @media (max-width: 1000px) {
                    padding: 40px 0 0;
                    border-bottom: 1px solid #e7e7e7 !important;
                }
            }
        }
    }

    .four {
        margin: 40px 0 30px;
        padding: 20px;
        background: #333a42;
        color: $white;
        display: block;

        p {
            color: $white;
        }

        h3,
        p {
            @media (max-width: 600px) {
                text-align: center;
            }
        }

        .left {
            margin: 70px auto;
            padding-bottom: 50px;
            text-align: center;
            position: relative;

            .icon-section {
                width: 258px;
                margin: auto;
                margin-left: 50px;
                border: none;
                display: inline-block;
                vertical-align: top;
                @media (max-width: 600px) {
                    margin-left: auto;
                }

                &:first-of-type {
                    margin-left: 0;
                }

                .icon-wrap {
                    width: 80px;
                    height: 80px;
                    margin-bottom: 15px;

                    img {
                        width: 100% !important;
                        height: 100% !important;
                    }
                }

                .icon-body {
                    width: auto;
                    margin-left: 0;

                    h3,
                    p {
                        text-align: center;
                    }
                }
            }
        }

        .right {
            display: none;
            width: 630px;
            margin-left: 60px;
            vertical-align: bottom;
            margin-bottom: -3px;

            img {
                width: 630px;
                height: auto;
            }
        }
    }

    .five {
        li {
            text-align: left;
            @media (max-width: 1200px) {
                padding-left: 68px;
            }
            @media (max-width: 600px) {
                margin: auto;
                padding: 0;
            }
        }

        .left {
            display: inline-block;
            width: 50%;
            margin-right: 60px;
            position: relative;
            vertical-align: top;
        }

        .right {
            display: inline-block;
            width: 43%;
            vertical-align: top;
        }

        .left,
        .right {
            @media (max-width: 1200px) {
                width: auto;
                margin: auto;
                padding: 0 20px;
                display: block;
            }

            .icon-wrap {
                @media (max-width: 1200px) {
                    margin-right: 15px;
                    float: left;
                }
            }

            .icon-section,
            .icon-body {
                @media (max-width: 1200px) {
                    width: auto;
                    margin: auto;
                    margin-left: auto;
                    display: block;
                }
            }

            .icon-section,
            .icon-section.noborder {
                @media (max-width: 1200px) {
                    padding: 40px 0 0;
                    border-bottom: 1px solid #e7e7e7 !important;
                }
            }
        }
    }
}

#about-page {
    .page {
        height: 450px;
        //noinspection CssUnknownTarget
        background-image: url('img/panda.jpg');
        background-position: center bottom;
        background-size: cover;

        .tagline {
            color: $blue;
            text-align: center;
            @media (max-width: 500px) {
                font-size: 30px;
            }
        }
    }

    .our-team {
        h1 {
            text-align: center;
            @media (max-width: 400px) {
                font-size: 45px;
            }
        }

        .intro-text {
            padding: 0 50px 60px;
            @media (max-width: 800px) {
                padding: 30px;
                font-size: 17px;
                line-height: 30px;
            }
            @media (max-width: 600px) {
                padding: 30px 10px 10px;
            }
            @media (max-width: 400px) {
                padding-top: 60px;
                font-size: 15px;
                line-height: 25px;
            }
        }

        .team-list {
            background: #f6f6f6;

            .team-list-wrapper {
                max-width: $pageWidth;
                margin: 0 auto;
                padding: 20px 30px 70px 30px;
                text-align: center;
                clear: both;

                h2 {
                    color: #7b7b7b;
                }

                .our-team-members {
                    width: 275px;
                    margin: 0 10px 30px;
                    display: inline-block;
                    vertical-align: top;
                    @media (max-width: 500px) {
                        width: 100%;
                        margin: 0 0 30px;
                    }

                    .team-member-small-info {
                        text-align: center;
                        background: $white;
                        border: 1px solid #f4f2f0;
                        border-radius: 5px;
                        padding-bottom: 10px;
                        cursor: pointer;

                        .team-member-headshot-container {
                            width: 100%;
                            height: 250px;
                            background-size: cover;
                            background-position: 50% 50%;
                            border-top-right-radius: 5px;
                            border-top-left-radius: 5px;
                        }

                        .staff-name-small {
                            color: #333a42;
                            font-size: 20px;
                            font-weight: bold;
                            line-height: 25px;
                            margin-bottom: 5px;
                            padding: 0 5px;
                        }

                        .team-member-position-small {
                            text-transform: uppercase;
                            color: #81878c;
                            font-family: 'Avenir W01', 'Helvetica Neue',
                                Helvetica, Arial, sans-serif;
                            font-size: 12px;
                            font-weight: 900;
                            letter-spacing: 0.88px;
                            line-height: 16px;
                            padding: 0;
                            margin: 0;
                            padding-left: 5px;
                            padding-right: 5px;
                        }

                        .team-member-view-email {
                            text-transform: uppercase;
                            padding-top: 10px;
                            border-top: 1px solid #f2f2f2;
                            color: #333a42;
                            font-size: 12px;
                            letter-spacing: 0.88px;
                            margin-top: 20px;
                            margin-bottom: 0;
                            transition: all 0.3s;

                            a:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
    }
}

#contact-page {
    .page {
        //noinspection CssUnknownTarget
        background-image: url('img/panda.jpg');
        background-position: center bottom;
        background-size: cover;

        .tagline {
            color: $blue;
            @media (max-width: 500px) {
                font-size: 30px;
            }
        }
    }

    .intro-text {
        @media (max-width: 400px) {
            padding: 60px 20px 40px;
            font-size: 17px;
            line-height: 28px;
        }
    }

    #contact-subtext {
        margin: 150px 0 0 0;
        color: $white;
        font: {
            @include size(1.3);
            weight: 300;
        }
        text-align: center;
        letter-spacing: 1px;
        @media (max-width: 1000px) {
            margin: 50px 20px 0 20px;
            line-height: 35px;
        }
        @media (max-width: 400px) {
            font: {
                @include size(1.1);
            }
            line-height: 25px;
        }
    }
}

#contact-location {
    padding: 50px 80px 100px 80px;
    background: $white;
    font: {
        @include size(1.2);
        weight: 700;
    }
    text-align: center;
    @media (max-width: 900px) {
        padding: 50px 50px 100px 50px;
    }
    @media (max-width: 700px) {
        padding: 50px 30px 100px 30px;
    }

    #contact-form {
        width: 43%;
        text-align: left;
        display: inline-block;
        @media (max-width: 1200px) {
            width: 42%;
        }
        @media (max-width: 700px) {
            width: 80%;
        }
        @media (max-width: 400px) {
            width: 95%;
            margin: 0 5% 0 0;
        }

        input {
            width: 100%;
            margin: 15px 0 15px 0;
            padding: 13px;
            border: solid 1px #cccccc;
            font: {
                @include size(1);
            }
            display: block;
            @media (max-width: 700px) {
                width: 95%;
            }
        }

        textarea {
            width: 100%;
            height: 250px;
            padding: 13px;
            border: solid 1px #cccccc;
            @media (max-width: 700px) {
                width: 95%;
            }
        }

        button {
            width: 120px;
            height: 50px;
            margin: 30px 0 0 0;
            background: $blue;
            border: none;
            color: $white;
            font: {
                @include size(1.2);
                weight: 700;
            }
            letter-spacing: 1px;
        }
    }

    #locate-connect {
        width: 43%;
        margin: 0 0 0 120px;
        text-align: left;
        display: inline-block;
        vertical-align: top;
        @media (max-width: 1100px) {
            margin: 0 0 0 100px;
        }
        @media (max-width: 900px) {
            margin: 0 0 0 80px;
        }
        @media (max-width: 700px) {
            width: 80%;
            margin: 80px auto 0 auto;
        }
        @media (max-width: 400px) {
            width: 100%;
        }

        #map-address {
            margin: 15px 0 20px 0;
            font: {
                @include size(1.3);
                weight: 300;
            }
            line-height: 26px;
        }

        iframe {
            width: 100%;
            height: 250px;
            margin: 0 0 30px 0;
        }

        img {
            margin: 15px 15px 0 0;
            display: inline-block;

            &:hover {
                cursor: pointer;
            }
        }
    }
}

#footer-wrapper {
    padding: 30px 30px 40px 30px;
    background: #3e3e3e;
    @media (max-width: 1000px) {
        padding: 20px 30px 25px 30px;
    }

    #footer {
        max-width: $pageWidth;
        margin: 0 auto;
        @media (max-width: 800px) {
            text-align: center;
        }

        .SE-logo {
            display: inline-block;
            width: 300px;
            @media (max-width: 1000px) {
                width: 200px;
            }
            @media (max-width: 800px) {
                margin: 0 auto 10px;
                display: block;
            }
        }

        .tagline {
            margin: 33px 0;
            color: $white;
            font-size: 25px;
            font-weight: bold;
            float: right;
            display: inline-block;
            vertical-align: top;
            @media (max-width: 1000px) {
                margin: 22px 0;
                font-size: 20px;
            }
            @media (max-width: 800px) {
                float: none;
            }
            @media (max-width: 600px) {
                width: 250px;
            }
        }
    }
}

#footer-wrapper-links {
    background: #000;
    padding: 20px;

    .footer-links {
        max-width: $pageWidth;
        margin: 0 auto;
        padding: 20px;

        &::after {
            content: '';
            display: block;
            clear: both;
        }

        .footer-left,
        .footer-right {
            width: 48%;
            color: $white;
            display: inline-block;
            vertical-align: middle;
            @media (max-width: 1200px) {
                width: auto;
                margin: 0 auto 15px;
                padding-bottom: 15px;
                border-bottom: solid 1px #777;
                text-align: center;
                display: block;
            }

            a {
                color: $white;
                padding: 0 15px;
                @media (max-width: 600px) {
                    padding: 10px 15px;
                    display: inline-block;
                }
            }
        }

        .footer-right {
            text-align: right;
            @media (max-width: 1200px) {
                margin-bottom: 0;
                padding: 0;
                border: none;
                text-align: center;
            }

            a {
                border-left: solid 1px #ececec;
                @media (max-width: 600px) {
                    border: none;
                }

                &:first-of-type {
                    border: none;
                }
            }
        }
    }
}

.our-work {
    width: 375px;
    margin: 0 10px 30px;
    display: inline-block;
    vertical-align: top;
    @media (max-width: 500px) {
        width: 100%;
        margin: 0 0 30px;
    }

    .our-work-small-info {
        border-radius: 5px;
        background-color: $white;
        border: 1px solid #f2f2f2;
        transition: all 0.3s;

        .our-work-small-image-container {
            width: 100%;
            height: 200px;
            background-size: cover;
            background-position: 50% 50%;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }

        .our-work-title-small {
            color: #333a42;
            font-size: 24px;
            font-weight: 300;
            line-height: 31px;
            margin-top: 25px;
            padding: 0 20px;
        }

        .our-work-small-description-short {
            padding: 0 20px;
            color: $black;
            font-size: 14px;
            font-weight: 300;
            letter-spacing: 1px;
            line-height: 19px;
            @media (max-width: 800px) {
                min-height: 100px;
            }
        }

        .our-work-small-view-project-tab {
            text-transform: uppercase;
            padding-top: 20px;
            padding-bottom: 20px;
            border-top: 1px solid #f2f2f2;
            background: rgba(255, 193, 9, 0.4);
            color: #333a42;
            font-size: 12px;
            font-weight: 900;
            letter-spacing: 0.88px;
            line-height: 16px;
            text-align: center;
            margin-top: 20px;
            transition: all 0.3s;

            &:hover {
                background-color: #333a42;
                color: white;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
                transition: all 0.3s;
            }
        }
    }
}

// Mobile menu button
// Mobile menu button
#mobileMenuButton {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 35px;
    left: 30px;
    display: none;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    @media (max-width: 800px) {
        display: block;
    }
    @media (max-width: 400px) {
        top: 30px;
        left: 20px;
    }

    span {
        display: block;
        position: absolute;
        height: 4px;
        width: 100%;
        background: #ececec;
        border-radius: 5px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.25s ease-in-out;
        -moz-transition: 0.25s ease-in-out;
        -o-transition: 0.25s ease-in-out;
        transition: 0.25s ease-in-out;
    }
}

#mobileMenuButton span:nth-child(1) {
    top: 0px;
}

#mobileMenuButton span:nth-child(2),
#mobileMenuButton span:nth-child(3) {
    top: 9px;
}

#mobileMenuButton span:nth-child(4) {
    top: 18px;
}

#mobileMenuButton.open span:nth-child(1) {
    top: 9px;
    width: 0%;
    left: 50%;
}

#mobileMenuButton.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

#mobileMenuButton.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

#mobileMenuButton.open span:nth-child(4) {
    top: 9px;
    width: 0%;
    left: 50%;
}

.hidden-for-realz {
    display: none;
}
